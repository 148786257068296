import {Injectable, Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {DatePipe} from "@angular/common";
import {Nullable} from "../utils";

const FORMAT = 'dd.MM.YYYY HH:mm:ss';

@Pipe({
  name: 'customDate'
})
@Injectable({providedIn: 'root'})
export class CustomDatePipe implements PipeTransform {
  constructor(private readonly datePipe: DatePipe) {
  }

  transform(value: any | Moment, format = FORMAT, timezone?: string, locale?: string): Nullable<string> {
    if (value instanceof moment) {
      value = (value as Moment).toJSON();
    }
    return this.datePipe.transform(value, format, timezone, locale);
  }
}
