import {Injectable} from "@angular/core";
import {Observable, ReplaySubject} from "rxjs";
import {Alert} from "./alert.model";
import {generateUniqueKey, Nullable} from "../../utils";

const DURATION = 4000;

@Injectable({providedIn: 'root'})
export class AlertService {
  private readonly alerts = new ReplaySubject<Nullable<Alert>>(1);

  success(message: string): void {
    const alert: Alert = {
      message,
      type: 'success'
    };

    this.add(alert);
  }

  error(message: string): void {
    const alert: Alert = {
      message,
      type: 'error'
    };

    this.add(alert);
  }

  info(message: string, duration?: number): void {
    const alert: Alert = {
      message,
      type: 'info'
    };

    this.add(alert, duration);
  }

  warning(message: string, duration?: number): void {
    const alert: Alert = {
      message,
      type: 'warning'
    };

    this.add(alert, duration);
  }

  get(): Observable<Nullable<Alert>> {
    return this.alerts.asObservable();
  }

  add(alert: Alert, duration?: number): void {
    alert.id = generateUniqueKey();
    alert.duration = duration ?? DURATION;
    alert.date = new Date();
    this.alerts.next(alert);
  }

  clear(): void {
    this.alerts.next(null);
  }
}
