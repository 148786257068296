import {ChangeDetectorRef, Directive, Host, OnInit, Optional} from "@angular/core";
import {NzButtonComponent} from "ng-zorro-antd/button";
import {LoadingService} from "@core/loading.service";
import {DestroyService} from "@core/destroy.service";
import {takeUntil} from "rxjs";

@Directive({
  selector: 'button[nz-loading-button]',
  providers: [DestroyService]
})
export class NzLoadingButtonDirective implements OnInit {
  constructor(@Host() @Optional() private readonly nzButtonComponent: NzButtonComponent,
              private readonly cdr: ChangeDetectorRef,
              private readonly destroy$: DestroyService,
              private readonly loadingService: LoadingService) {
  }

  ngOnInit(): void {
    if (this.nzButtonComponent) {
      this.listenLoadingState();
    }
  }

  private listenLoadingState(): void {
    this.loadingService.loading
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(isLoading => {
        this.nzButtonComponent.nzLoading = isLoading;
        this.cdr.detectChanges();
      });
  }
}
