import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {Nullable} from "../utils";
import {MaskPipe} from "ngx-mask";

@Pipe({
  name: 'accountMask',
})
@Injectable({providedIn: 'root'})
export class AccountMaskPipe implements PipeTransform {
  constructor(private readonly maskPipe: MaskPipe) {
  }

  transform(value: string): Nullable<string> {
    if (!value) {
      return null;
    }

    if (value.length === 6) {
      return value;
    }

    if (value.length === 16) {
      const masked = this.maskPipe.transform(value, '0000 0000 0000 0000');
      return `${masked.substring(0, 5)}**** ****${masked.substring(14, masked.length)}`;
    }

    return `${value.substring(0, 6)}********${value.substring(12, value.length)}`;
  }
}
