import {Pipe, PipeTransform} from "@angular/core";
import {Nullable} from "../utils";

@Pipe({
  name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {
  transform(value: string, limit = 10): Nullable<string> {
    if (value?.length > limit) {
      return `${value.substr(0, limit)}...`;
    }

    return value;
  }
}
