// These constants are injected via webpack DefinePlugin variables.
// You can add more variables in webpack.common.js or in profile specific webpack.<dev|prod>.js files.
// If you change the values in the webpack config files, you need to re run webpack to update the application
import {environment} from "../environments/environment";

declare const __APP_VERSION__: string;
declare const __API_URL__: string;
declare const __RECAPTCHA_SITE_KEY__: string;
declare const __GOOGLE_ANALYTICS_TRACK_CODE__: string;
declare const __CONTENT_URL__: string;

export const FAKE_MODE = environment.fakeMode;
export const APP_VERSION = __APP_VERSION__;
export const API_URL = __API_URL__ || environment.apiUrl;
export const RECAPTCHA_SITE_KEY = __RECAPTCHA_SITE_KEY__ || environment.recaptchaSiteKey;
export const GOOGLE_ANALYTICS_TRACK_CODE = __GOOGLE_ANALYTICS_TRACK_CODE__ || environment.googleAnalyticsTrackCode;
export const CONTENT_URL = __CONTENT_URL__ || environment.contentUrl;
