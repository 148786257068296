import {Directive, HostListener, Input} from "@angular/core";
import * as XLSX from 'xlsx';

@Directive({
  selector: 'button[xls-downloader]'
})
export class XlsDownloaderDirective {
  @Input()
  tableId: string;

  @Input()
  fileName: string;

  @HostListener('click')
  download(): void {
    const fileName = this.fileName || 'export-' + new Date().getTime();

    const table = this.tableId ? document.getElementById(this.tableId) : document.getElementsByTagName('nz-table')[0];

    const ws3 = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws3, fileName);
    XLSX.writeFile(wb, fileName + '.xls');
  }
}
