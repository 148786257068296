import {Directive, HostListener} from "@angular/core";
import {NzModalService} from "ng-zorro-antd/modal";

@Directive({
  selector: 'button[modalCloser]'
})
export class ModalCloserDirective {
  constructor(private readonly modalService: NzModalService) {
  }

  @HostListener('click')
  close(): void {
    this.modalService.closeAll();
  }
}
