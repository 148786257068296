import {Directive, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {Role} from "@config/role.constants";
import {AccountService} from "@account/account.service";

@Directive({
  selector: '[hasRole]'
})
export class HasRoleDirective {
  private readonly destroy$ = new Subject<void>();

  constructor(private readonly accountService: AccountService,
              private readonly templateRef: TemplateRef<any>,
              private readonly viewContainerRef: ViewContainerRef) {
  }

  @Input()
  set hasRole(value: Role | Role[]) {
    this.accountService.getAuthenticationState()
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this.updateView(value));
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private updateView(role: Role | Role[]): void {
    const hasRole = this.accountService.hasRole(role);
    this.viewContainerRef.clear();
    if (hasRole) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    }
  }
}
