<div class="modal-header d-flex justify-content-between align-items-center mb-4">
  <!--Don't remove this tag (for correcting position)-->
  <button nz-button nzType="link" [style.visibility]="'hidden'">
    <img class="close-opacity cursor-pointer" lazyLoad="assets/images/icons/close.svg"
         defaultImage="assets/images/no-image.svg" alt="close" width="25"
         height="25" title="Закрыть">
  </button>

  <div class="d-flex align-items-center">
    <ng-container *ngIf="iconPath">
      <img class="category-img-filter" [lazyLoad]="iconPath" defaultImage="assets/images/no-image.svg"
           alt="icon" height="22">
    </ng-container>

    <span class="fs-16 ml-1 font-weight-500 c-white">{{title}}</span>
  </div>

  <button nz-button nzType="link" modalCloser>
    <img class="close-opacity cursor-pointer mr-3" lazyLoad="assets/images/icons/close.svg"
         defaultImage="assets/images/no-image.svg" alt="close" width="25"
         height="25" title="Закрыть">
  </button>
</div>
