import {NgControl} from '@angular/forms';
import {Directive, Input} from '@angular/core';

@Directive({
  selector: '[formControlDisabled]',
})
export class FormControlDisabledDirective {
  constructor(private readonly control: NgControl) {
  }

  @Input()
  set formControlDisabled(disabled: boolean) {
    if (!this.control.control) {
      throw Error('FormControl was not attached!');
    }

    if (disabled) {
      this.control.control.disable({emitEvent: false});
    } else {
      this.control.control.enable({emitEvent: false});
    }
  }
}
