import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgZorroModule} from "./ng-zorro.module";
import {DirectiveModule} from "./directive/directive.module";
import {NgxMaskModule} from "ngx-mask";
import {ComponentModule} from "./component/component.module";
import {PipeModule} from "./pipe/pipe.module";
import {AlertModule} from "./component/alert/alert.module";
import {LazyLoadImageModule} from "ng-lazyload-image";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroModule,
    DirectiveModule,
    ComponentModule,
    PipeModule,
    AlertModule,
    LazyLoadImageModule,
    NgxMaskModule.forRoot()
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgZorroModule,
    DirectiveModule,
    ComponentModule,
    NgxMaskModule,
    PipeModule,
    AlertModule,
    LazyLoadImageModule
  ],
})
export class SharedModule {
}
