import {AfterViewInit, Directive, ElementRef} from "@angular/core";

@Directive({
  selector: 'input[auto-focus]'
})
export class AutoFocusDirective implements AfterViewInit {
  constructor(private readonly elRef: ElementRef<HTMLElement>) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => this.elRef.nativeElement.focus());
  }
}
