import {ChangeDetectionStrategy, Component, Input, Optional} from "@angular/core";
import {ModalCloserDirective} from "./modal-closer.directive";

@Component({
  selector: 'modal-header',
  templateUrl: 'modal-header.component.html',
  styleUrls: ['modal-header.scss'],
  providers: [ModalCloserDirective],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalHeaderComponent {
  constructor(@Optional() private readonly modalCloserDirective: ModalCloserDirective) {
  }

  @Input()
  title: string;

  @Input()
  iconPath?: string;

  closeModal(): void {
    this.modalCloserDirective?.close();
  }
}
