import {Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {registerLocaleData} from "@angular/common";
import ru from '@angular/common/locales/ru';
import {RouterModule} from "@angular/router";
import {APP_ROUTES} from "./app.route";
import {ErrorComponent} from "@core/result/error.component";
import {HTTP_INTERCEPTORS, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {NgxWebstorageModule} from "ngx-webstorage";
import {NgProgressModule} from "ngx-progressbar";
import {NgProgressHttpModule} from "ngx-progressbar/http";
import {NzMessageModule} from "ng-zorro-antd/message";
import {LoginComponent} from "@auth/login/login.component";
import {SharedModule} from "@shared/shared.module";
import {TranslateModule} from "@ngx-translate/core";
import {LanguageService} from "@core/util/language.service";
import {ServiceWorkerModule} from "@angular/service-worker";
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from "ng-recaptcha";
import {NZ_I18N, ru_RU} from "ng-zorro-antd/i18n";
import {NZ_ICONS} from "ng-zorro-antd/icon";
import {NZ_ICONS_DEFINITION} from "@config/nz-icons";
import {RequestHandlerInterceptor} from "@core/interceptor/request-handler.interceptor";
import {inject} from "@shared/utils";
import {TokenService} from "@core/token/token.service";
import {NzResultModule} from "ng-zorro-antd/result";
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from "ngx-google-analytics";
import {FAKE_MODE, GOOGLE_ANALYTICS_TRACK_CODE, RECAPTCHA_SITE_KEY} from "./app.constants";
import {NzCollapseModule} from "ng-zorro-antd/collapse";
import {FooterModule} from "@shared/blocks/footer/footer.module";
import {PasswordInputModule} from "@shared/component/password-input/password-input.module";

export let InjectorInstance: Injector;

registerLocaleData(ru);

@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES),
    SharedModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgProgressHttpModule,
    NzMessageModule,
    NzResultModule,
    RecaptchaV3Module,
    ServiceWorkerModule.register('ngsw-worker.js', {enabled: false}),
    NgxGoogleAnalyticsModule.forRoot(GOOGLE_ANALYTICS_TRACK_CODE),
    NgxGoogleAnalyticsRouterModule,
    NzCollapseModule,
    TranslateModule.forRoot(),
    NgxWebstorageModule.forRoot({caseSensitive: true, separator: '-'}),
    FooterModule,
    PasswordInputModule,
    NgProgressModule.withConfig({
      spinnerPosition: 'left',
      color: '#fff',
    }),
  ],
  declarations: [
    AppComponent,
    ErrorComponent,
    LoginComponent,
  ],
  providers: [
    {
      provide: NZ_I18N,
      useValue: ru_RU
    },
    {
      provide: NZ_ICONS,
      useValue: NZ_ICONS_DEFINITION,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestHandlerInterceptor,
      multi: true,
    },
    {
      provide: 'canActivateLogin',
      useValue: (): boolean => !inject(TokenService).isStable
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useFactory: () => {
        if (FAKE_MODE) {
          return null;
        }

        return RECAPTCHA_SITE_KEY;
      }
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(injector: Injector, langService: LanguageService) {
    InjectorInstance = injector;
    langService.change('ru');
  }
}
