import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable, tap} from "rxjs";
import {Token} from "@core/token/token.model";
import {Router} from "@angular/router";
import {TokenService} from "@core/token/token.service";
import {API_URL} from "../app.constants";
import {LoginCredentials} from "@auth/login/login-credentials.model";
import {AccountService} from "@account/account.service";
import {NzModalService} from "ng-zorro-antd/modal";

@Injectable({providedIn: 'root'})
export class AuthService {
  constructor(private readonly http: HttpClient,
              private readonly tokenService: TokenService,
              private readonly accountService: AccountService,
              private readonly router: Router,
              private readonly modalService: NzModalService) {
  }

  login(credentials: LoginCredentials): Observable<Token> {
    return this.http.post<Token>(AuthService.getAuthUrl('token'), credentials, {observe: 'body'})
      .pipe(
        tap((response: Token) => this.tokenService.token = response)
      );
  }

  logoutInClient(): void {
    this.modalService.closeAll();
    this.tokenService.clear();
    this.accountService.authenticate(null);
    this.router.navigate(['login']).then();
  }

  static getAuthUrl(suffix: string): string {
    return `${API_URL}/api/auth/${suffix}`;
  }
}
