import {Injectable, Pipe, PipeTransform} from "@angular/core";
import {MaskPipe} from "ngx-mask";
import {amount} from "../functions/amount";

const DEFAULT_RATIO = 1;

@Pipe({
  name: 'amount'
})
@Injectable({providedIn: 'root'})
export class AmountPipe implements PipeTransform {
  constructor(private readonly maskPipe: MaskPipe) {
  }

  transform(value: number, ratio = DEFAULT_RATIO): string | number {
    if (!value) return 0;

    return this.maskPipe.transform(amount(value, ratio), 'separator.2');
  }
}
