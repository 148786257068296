import {NgModule} from "@angular/core";
import {SharedModule} from "../../shared.module";
import {PasswordInputComponent} from "./password-input.component";

@NgModule({
  imports: [SharedModule],
  declarations: [PasswordInputComponent],
  exports: [PasswordInputComponent]
})
export class PasswordInputModule {
}
