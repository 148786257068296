import {Injectable} from "@angular/core";
import {LanguageService} from "@core/util/language.service";
import {I18n} from "../../model/i18n.model";
import {Lang} from "../../model/language.enum";

@Injectable({providedIn: 'root'})
export class BmI18nService {
  constructor(private readonly langService: LanguageService) {
  }

  getText(i18n: I18n[] | object): string | null {
    if (!i18n) {
      return null;
    }

    if (typeof i18n == 'object') {
      return i18n[this.langService.getCurrent()]
    }

    if (Array.isArray(i18n)) {
      return (i18n as Array<I18n>).find(i18n => i18n.lang === Lang[this.langService.getCurrent().toUpperCase()])?.text!;
    }

    return '';
  }
}
