import {ChangeDetectionStrategy, Component} from "@angular/core";
import {APP_VERSION} from "@app/app.constants";

@Component({
  selector: 'app-footer',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
      <footer class="text-center mt-2 font-weight-600">
        <a class="underline-on-hover mr-2" href="https://devops.uz" target="_blank">2022 DEVOPS LLC &nbsp; v{{version}}</a>
      </footer>
  `,
  styles: [`
    :host(.c-white) {
      a, span {
        color: white;
      }
    }
  `],
})
export class FooterComponent {
  readonly version = APP_VERSION;
}
