<div class="primary-bg">
  <div class="d-flex justify-content-center align-items-center" [style.height]="'95vh'">
    <div class="p-3 w-300">
      <div class="mb-4">
        <img lazyLoad="assets/images/logo/logo-dark-full.svg" defaultImage="assets/images/no-image.svg" alt="logo-dark-full" width="200" height="35">
        <span class="c-primary font-weight-700" [style.vertical-align]="'-5px'">v{{version}}</span>
      </div>

      <alert></alert>

      <form class="mb-5" [formGroup]="editForm" (ngSubmit)="onSubmit()">
        <div class="mb-3">
          <label for="field_username">Логин</label>
          <input nz-input nzSize="large" formControlName="username" id="field_username" autocomplete="off" auto-focus>
        </div>

        <div class="mb-4">
          <label for="field_password">Пароль</label>
          <password-input id="field_password" formControlName="password"></password-input>
        </div>

        <div class="mb-4" *fakeMode>
          <label for="field_role">Роль</label>
          <bm-select-input id="field_role" [(ngModel)]="role" [ngModelOptions]="{standalone: true}" [data]="roles"></bm-select-input>
        </div>

        <div class="d-flex justify-content-center">
          <button nz-button type="submit" nzSize="large" class="w-100" nzType="primary" nz-loading-button>Войти</button>
        </div>
      </form>
    </div>
  </div>

  <app-footer></app-footer>
</div>
