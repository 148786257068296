import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'more',
})
export class MorePipe<T> implements PipeTransform {
  transform(data: T[], limit: number, show?: boolean): T[] {
    if (!limit) {
      throw Error('Field `limit` does not set');
    }

    if (!show) {
      return data.slice(0, limit);
    }

    return data;
  }
}
