import {Router} from '@angular/router';
import {Type} from "@angular/core";
import {InjectorInstance} from "../app.module";

export type Nullable<T> = T | null;

export const inject = <T>(service: Type<T>): T => InjectorInstance.get<T>(service);

export function getRouteUrlWithoutParams(router: Router): Nullable<string> {
  if (!router?.url) {
    return null;
  }

  return router.url.split('?')[0];
}

export const isNullOrUndefined = <T>(val: T): boolean => val === undefined || val === null;

export const isEmpty = (str: string): boolean => str === '';

export function generateUniqueKey(): string {
  const s4 = () =>
    Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);

  return `${s4()}-${s4()}-${s4()}-${s4()}`;
}

export const hasSize = (array: unknown[]): boolean => array && !!array.length;

export const remove = <T>(array: T[], compareKey: keyof T, compareValue: unknown) => {
  const index = array.findIndex(item => item[compareKey] === compareValue);
  array.splice(index, 1);
}

export const openPDF = (data: Blob): void => {
  const file = new Blob([data], {type: 'application/pdf'});
  const fileURL = URL.createObjectURL(file);
  window.open(fileURL);
}

export const isEmptyObject = (obj: any): boolean => Object.keys(obj)?.length === 0;

export const startDay = (value?: Date): Date => {
  const date = createDate(value);
  date.setHours(0, 0, 0);
  return date;
};

export const endDay = (value?: Date): Date => {
  const date = createDate(value);
  date.setHours(23, 59, 59);
  return date;
};

export const createDate = (value: number | string | Date): Date => {
  if (!value) {
    return new Date();
  }

  return new Date(value);
}
