import {Component} from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
    <ng-progress [spinner]="false" spinnerPosition="right" direction="ltr+"
                 [thick]="false" [fixed]="true">
    </ng-progress>
    <router-outlet></router-outlet>
  `
})
export class AppComponent {
}
