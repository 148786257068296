import {Injectable} from "@angular/core";
import jwtDecode from "jwt-decode";
import {catchError, Observable, of, tap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {AuthService} from "@auth/auth.service";
import {LocalStorageService} from "ngx-webstorage";
import {Token} from "@core/token/token.model";

@Injectable({providedIn: 'root'})
export class TokenService {
  constructor(private readonly http: HttpClient,
              private readonly localStorageService: LocalStorageService) {
  }

  set token(token: Token) {
    if (token) {
      this.localStorageService.store('access_token', token.access_token);
      this.localStorageService.store('refresh_token', token.refresh_token);
    }
  }

  get token(): Token {
    return {
      access_token: this.localStorageService.retrieve('access_token'),
      refresh_token: this.localStorageService.retrieve('refresh_token')
    };
  }

  get isStable(): boolean {
    return !!this.token.access_token;
  }

  refreshToken(): Observable<Token> {
    const req = {
      refreshToken: this.token.refresh_token
    };

    return this.http.post<Token>(AuthService.getAuthUrl('refresh'), req, {observe: 'body'})
      .pipe(
        catchError(() => of(null)),
        tap((response: Token) => this.token = response)
      );
  }

  clear(): void {
    this.localStorageService.clear('access_token');
    this.localStorageService.clear('refresh_token');
  }

  decode(): any {
    const accessToken = this.token.access_token;
    if (!accessToken) {
      return null;
    }

    return jwtDecode(accessToken);
  }
}
