import {NgModule} from "@angular/core";
import {AlertComponent} from "./alert.component";
import {NzAlertModule} from "ng-zorro-antd/alert";
import {CommonModule} from "@angular/common";

@NgModule({
  imports: [
    CommonModule,
    NzAlertModule,
  ],
  declarations: [AlertComponent],
  exports: [AlertComponent],
})
export class AlertModule {

}
