import {ChangeDetectionStrategy, Component, forwardRef, Input} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";

@Component({
  selector: 'date-picker',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerComponent),
      multi: true
    }
  ],
  template: `
    <nz-date-picker [nzFormat]="format" [nzInputReadOnly]="inputReadOnly" [nzShowTime]="showTime"
                    [nzAllowClear]="allowClear" [(ngModel)]="date"></nz-date-picker>
  `,
  host: {
    '[class.w-100]': 'true'
  }
})
export class DatePickerComponent implements ControlValueAccessor {
  @Input()
  format = 'dd.MM.YYYY';

  @Input()
  allowClear = true;

  @Input()
  showTime: boolean;

  @Input()
  inputReadOnly = true;

  private _date: Date;

  set date(date: Date) {
    this._date = date;

    if (!date) {
      this.onChange(null);
    } else {
      this.onChange(new Date(date).toJSON());
    }
  }

  get date(): Date {
    return this._date;
  }

  onChange: (v: string) => void = () => {
  };

  private onTouched = () => {
  };

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  writeValue(date: Date): void {
    this._date = date;
  }
}
