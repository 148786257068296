import {ActivatedRouteSnapshot, CanActivate, Router} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable, isDevMode} from '@angular/core';
import {map} from 'rxjs/operators';
import {Account} from '@account/account.model';
import {AccountService} from "@account/account.service";
import {Nullable} from "../shared/utils";

@Injectable({providedIn: 'root'})
export class CanActivateGuard implements CanActivate {
  constructor(private readonly accountService: AccountService,
              private readonly router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.accountService.identity().pipe(
      map((account: Nullable<Account>) => {
        if (!account) {
          this.router.navigate(['login']).then();
          return false;
        }

        if (this.accountService.isAgent() && !this.accountService.isSigned() && !route.routeConfig.path.includes('offer')) {
          this.router.navigate(['offer']);
          return false;
        }

        const authorities = route.data!['roles'];

        if (!authorities || !authorities.length) {
          return true;
        }

        if (this.accountService.hasRole(authorities)) {
          return true;
        }

        if (isDevMode()) {
          console.error('User has not any of required roles: ', authorities);
        }

        this.router.navigate(['403']).then();
        return false;
      })
    );
  }
}
