import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from "@angular/core";
import {DestroyService} from "@core/destroy.service";
import {takeUntil} from "rxjs";
import {Alert} from "./alert.model";
import {remove} from "../../utils";
import {AlertService} from "@shared/component/alert/alert.service";

const LIMIT = 1;

@Component({
  selector: 'alert',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DestroyService],
  template: `
    <ng-container *ngIf="alerts.length">
      <nz-alert class="mb-3" *ngFor="let alert of alerts" [nzMessage]="alert.message" [nzType]="alert.type"
                nzShowIcon></nz-alert>
    </ng-container>
  `
})
export class AlertComponent implements OnInit, OnDestroy {
  @Input()
  limit = LIMIT;

  alerts: Alert[] = [];

  constructor(private readonly destroy$: DestroyService,
              private readonly cdr: ChangeDetectorRef,
              private readonly alertService: AlertService) {
  }

  ngOnInit(): void {
    this.subscribeToPusher();
  }

  ngOnDestroy(): void {
    this.alertService.clear();
    this.alerts = [];
  }

  private subscribeToPusher(): void {
    this.alertService.get()
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((alert: Alert) => this.onReceived(alert));
  }

  private onReceived(alert: Alert): void {
    if (!alert) {
      this.alerts = [];
    } else {
      this.push(alert);
    }

    this.cdr.markForCheck();
  }

  private push(alert: Alert): void {
    let {alerts, limit} = this;
    alerts.push(alert);

    if (alerts.length > limit) {
      alerts = alerts.slice(alerts.length - limit, alerts.length);
    }

    this.alerts = alerts;
    if (alert.duration !== 0) {
      setTimeout(() => this.remove(alert), alert.duration);
    }

    window.scrollTo(0, 0);
  }

  private remove(alert: Alert): void {
    if (!this.alerts.includes(alert)) return;

    remove(this.alerts, 'id', alert.id);
    this.cdr.markForCheck();
  }
}
