import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'numberToWords'
})
export class NumberToWordsPipe implements PipeTransform {

  transform(amount: number): string | number {
    if (!amount || !isInteger(amount)) {
      return amount;
    }

    return numToWords(amount / 100);
  }
}

const isInteger = function (x: any) {
  return x % 1 === 0;
}

const arr = (x: Iterable<unknown> | ArrayLike<unknown>) => Array.from(x);
const num = (x: any) => Number(x) || 0;
const isEmpty = (xs: string | any[]) => xs.length === 0;
const take = (n: any) => (xs: string | any[]) => xs.slice(0, n);
const drop = (n: any) => (xs: string | any[]) => xs.slice(n);
const reverse = (xs: any[]) => xs.slice(0).reverse();
const comp = (f: { (xs: any): any; (x: any): boolean; (arg0: any): any; }) => (g: (arg0: any) => any) => (x: any) => f(g(x));
const not = (x: any) => !x;
const chunk = (n: number) => {
  return (xs: string | any[]): any => {
    return isEmpty(xs) ? [] : [take(n)(xs), ...chunk(n)(drop(n)(xs))];
  };
};

const numToWords = (n: number | string): any => {
  const a = [
    '', 'один', 'два', 'три', 'четыре',
    'пять', 'шесть', 'семь', 'восем', 'девять',
    'десять', 'одинадцать', 'двенадцать', 'тринадцать', 'четырнадцать',
    'пятнадцать', 'шестнадцать', 'семьнадцать', 'восемнадцать', 'девятьнадцать'
  ];

  const b = [
    '', '', 'двадцать', 'тридцать', 'сорок',
    'пятьдесят', 'шестьдесят', 'семьдесят', 'восемьдесят', 'девяносто'
  ];

  const g = [
    '', 'тысяча', 'миллион', 'миллиард', 'триллион', 'квадриллион',
    'квинтиллион', 'секстиллион', 'септиллион', 'октиллион', 'нониллион'
  ];

  const makeGroup = ([ones, tens, huns]: any): any => {
    return [
      num(huns) === 0 ? '' : a[huns] + ' сто ',
      num(ones) === 0 ? b[tens] : b[tens] && b[tens] + '-' || '',
      a[tens + ones] || a[ones]
    ].join('');
  };

  const thousand = (group: string, i: string | number) => group === '' ? group : `${group} ${g[i]}`;

  if (typeof n === 'number') return numToWords(String(n));

  if (n === '0') return 'ноль';

  return comp(chunk(3))(reverse)(arr(n))
    .map(makeGroup)
    .map(thousand)
    .filter(comp(not)(isEmpty))
    .reverse()
    .join(' ');
};
