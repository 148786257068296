import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {NzResultStatusType} from "ng-zorro-antd/result/result.component";
import {NzModalService} from "ng-zorro-antd/modal";

@Component({
  template: `
    <nz-result [nzStatus]="code" [nzTitle]="title" [nzSubTitle]="subTitle">
      <div nz-result-extra>
        <button nz-button nzType="primary" nzSize="large" (click)="back()">Вернуться на главную</button>
      </div>
    </nz-result>
  `
})
export class ErrorComponent implements OnInit {
  code: NzResultStatusType;
  title: string;
  subTitle: string;

  constructor(private readonly activatedRoute: ActivatedRoute,
              private readonly modalService: NzModalService) {
  }

  ngOnInit(): void {
    this.modalService.closeAll();
    this.activatedRoute.data.subscribe(({title, subTitle}) => {
      this.code = this.activatedRoute.routeConfig.path as NzResultStatusType;
      this.title = title;
      this.subTitle = subTitle;
    });
  }

  back = (): void => window.history.back();
}
