import {NgModule} from "@angular/core";
import {PercentagePipe} from "./percentage.pipe";
import {CustomDatePipe} from "./custom-date.pipe";
import {DatePipe} from "@angular/common";
import {AccountMaskPipe} from "./account-mask.pipe";
import {EllipsisPipe} from "./ellipsis.pipe";
import {AmountPipe} from "./amount.pipe";
import {MaskPipe} from "ngx-mask";
import {SortByPipe} from "./sort-by.pipe";
import {MorePipe} from "./more.pipe";
import {NumberToWordsPipe} from "./number-to-word.pipe";

@NgModule({
  declarations: [PercentagePipe, CustomDatePipe, AccountMaskPipe, EllipsisPipe, AmountPipe, SortByPipe, MorePipe, NumberToWordsPipe],
  exports: [PercentagePipe, CustomDatePipe, AccountMaskPipe, EllipsisPipe, AmountPipe, SortByPipe, MorePipe, NumberToWordsPipe],
  providers: [PercentagePipe, CustomDatePipe, DatePipe, AmountPipe, MaskPipe]
})
export class PipeModule {
}
