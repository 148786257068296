import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Agent, DetailedAgent} from './agent.model';
import {SimpleResultData} from "@shared/model/simple-result-data.model";
import {getEndpointFor} from "@shared/functions/endpoint";
import {NglFilterField, NglFilterFieldType} from "ngl-filter-field";
import {createRequestOption} from "@core/util/request-util";

@Injectable({providedIn: 'root'})
export class AgentService {
  readonly resourceUrl = getEndpointFor('api/agents');

  constructor(private readonly http: HttpClient) {
  }

  create(agent: Agent): Observable<Agent> {
    return this.http.post<Agent>(this.resourceUrl, agent, {observe: 'body'});
  }

  createWithPassword(detailedAgent: DetailedAgent): Observable<Agent> {
    return this.http.post<Agent>(getEndpointFor('api/bm-agent') + '/save-with-user', detailedAgent, {observe: 'body'});
  }

  update(agent: Agent): Observable<Agent> {
    return this.http.put<Agent>(`${this.resourceUrl}/${agent.id}`, agent, {observe: 'body'});
  }

  resetPassword(shortname: string): Observable<any> {
    return this.http.get(getEndpointFor('api/bm-agent/reset-password'), {observe: 'body', params: {shortname}});
  }

  toggleActivation(agent: Agent): Observable<any> {
    const apiUrl = agent.active ? 'api/bm-agent/unblock' : 'api/bm-agent/block';
    return this.http.get(getEndpointFor(apiUrl), {params: {shortname: agent.shortName}, observe: 'body'});
  }

  // Used in agent-limit-control component
  setLimit(shortname: string, limit: number): Observable<any> {
    const req = {shortname, limit};
    return this.http.post(getEndpointFor('api/bm-agent/limit'), req, {observe: 'body'});
  }

  find(id: number): Observable<Agent> {
    const params = {withBalance: true, withDebit: true};
    return this.http.get<Agent>(`${this.resourceUrl}/${id}`, {observe: 'body', params});
  }

  query(req?: any): Observable<HttpResponse<Agent[]>> {
    return this.http.get<Agent[]>(this.resourceUrl, {observe: 'response', params: createRequestOption(req)});
  }

  delete(shortname: string): Observable<{}> {
    return this.http.delete(`${this.resourceUrl}/${shortname}`, {observe: 'body'});
  }

  getClientNameFromNCI(id: string): Observable<SimpleResultData<string>> {
    return this.http.get<SimpleResultData<string>>(`${getEndpointFor('api/bm-agent/nci/client')}/${id}`, {observe: 'body'})
  }

  getCurrent(): Observable<Agent> {
    return this.http.get<Agent>(getEndpointFor('api/bm-agent/current/agent'), {observe: 'body'})
  }

  getFilterFields(): NglFilterField[] {
    return [
      {
        name: 'bankClientId.equals',
        type: NglFilterFieldType.TEXT,
        translation: 'ID (уникальный код агента)',
        placeholder: 'Введите уникальный код агента'
      },
      {
        name: 'shortName.in',
        type: NglFilterFieldType.MULTI_SELECT,
        translation: 'Список агентов',
        placeholder: 'Выберите из списка',
        options: {
          nzLabel: 'shortName',
          nzValue: 'shortName',
          resourceUrl: this.resourceUrl
        },
      },
      {
        name: 'inn.contains',
        type: NglFilterFieldType.TEXT,
        translation: 'ИНН/ПИНФЛ',
        placeholder: 'Введите ИНН или ПИНФЛ',
        options: {
          mask: '00000000000000'
        }
      },
      {
        name: 'signed',
        type: NglFilterFieldType.SELECT,
        translation: 'Статус подписания договора',
        placeholder: 'Выберите из списка',
        options: {
          nzLabel: 'label',
          nzValue: 'value',
          items: [
            {
              label: 'Подписано',
              value: true
            },
            {
              label: 'Не подписано',
              value: false
            }
          ],
        }
      },
    ];
  }
}
