import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'percentage'
})
export class PercentagePipe implements PipeTransform {
  transform(value: number | string, percent: number): number {
    value = +value;

    if (!percent) {
      return value;
    }

    return value + (value * percent) / 100;
  }
}
