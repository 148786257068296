import {Injectable} from "@angular/core";
import {catchError, Observable, of, ReplaySubject, shareReplay, switchMap, tap} from "rxjs";
import {Role} from "@config/role.constants";
import {AgentService} from "../../dashboard/agent/agent.service";
import {Agent} from "../../dashboard/agent/agent.model";
import {map} from "rxjs/operators";
import {inject, Nullable} from "../../shared/utils";
import {FAKE_MODE} from "../../app.constants";
import {LocalStorageService} from "ngx-webstorage";
import {Account} from "@account/account.model";
import {TokenService} from "@core/token/token.service";

@Injectable({providedIn: 'root'})
export class AccountService {
  private accountCache$?: Observable<Nullable<Account>> | null;
  private authenticationState = new ReplaySubject<Nullable<Account>>(1);
  private account: Nullable<Account>;

  constructor(private readonly tokenService: TokenService,
              private readonly agentService: AgentService) {
  }

  authenticate(identity: Nullable<Account>) {
    if (!identity) {
      this.accountCache$ = null;
    }

    this.account = identity;
    this.authenticationState.next(identity);
  }

  identity(force?: boolean): Observable<Nullable<Account>> {
    if (!this.tokenService.isStable) {
      return of(null);
    }

    if (!this.accountCache$ || force) {
      this.accountCache$ = this.tokenService.refreshToken()
        .pipe(
          switchMap(() => {
            const account = this.parseToken();

            if (FAKE_MODE) {
              account.roles = [inject(LocalStorageService).retrieve('fake-role')];
            }

            if (account.roles.includes(Role.ADMIN)) {
              return of(account);
            }

            return this.getCurrentAgent(!force ? account : null)
              .pipe(
                catchError(() => {
                  this.tokenService.clear();
                  return of(null);
                }),
                map((agent: Agent) => {
                  // This case for fixing BM issue
                  if (account.roles.includes(Role.SUB_AGENT) && account.roles.includes(Role.AGENT)) {
                    const index = account.roles.findIndex(role => role === Role.AGENT);
                    account.roles.splice(index, 1);
                  }

                  return {...account, agent: agent};
                })
              );
          }),
          tap((account: Nullable<Account>) => this.authenticate(account)),
          shareReplay()
        );
    }

    return this.accountCache$;
  }

  getAuthenticationState(): Observable<Nullable<Account>> {
    return this.authenticationState.asObservable();
  }

  hasRole(roles: Role | Role[]): boolean {
    if (!roles) {
      return true;
    }

    if (!this.account) {
      return false;
    }

    if (!Array.isArray(roles)) {
      roles = [roles];
    }

    return this.account.roles?.some(userRole => roles.includes(userRole));
  }

  isAgent(): boolean {
    return this.hasRole(Role.AGENT) && !!this.account.agent;
  }

  isSigned(): boolean {
    return this.account.agent && this.account.agent.signed;
  }

  private parseToken(): Account {
    const decodedToken = this.tokenService.decode();

    return {
      id: decodedToken.sub,
      firstName: decodedToken.given_name,
      lastName: decodedToken.family_name,
      fullName: decodedToken.name,
      email: decodedToken.email,
      emailVerified: decodedToken.email_verified,
      username: decodedToken.preferred_username,
      roles: decodedToken.roles
    };
  }

  private getCurrentAgent(account?: Account): Observable<Agent> {
    if (account && account.agent) {
      return of(account.agent);
    }

    return this.agentService.getCurrent();
  }
}
