import {NgModule} from "@angular/core";
import {AutoFocusDirective} from "./auto-focus.directive";
import {FormControlDisabledDirective} from "./form-control-disabled.directive";
import {HasRoleDirective} from "./has-role.directive";
import {XlsDownloaderDirective} from "./xls-downloader.directive";
import {DefaultImageDirective} from "./default-image.directive";
import {FakeModeDirective} from "./fake-mode.directive";
import {NzLoadingButtonDirective} from "./nz-loading-button.directive";

@NgModule({
  declarations: [
    AutoFocusDirective,
    FormControlDisabledDirective,
    HasRoleDirective,
    XlsDownloaderDirective,
    DefaultImageDirective,
    FakeModeDirective,
    NzLoadingButtonDirective
  ],
  exports: [
    AutoFocusDirective,
    FormControlDisabledDirective,
    HasRoleDirective,
    XlsDownloaderDirective,
    DefaultImageDirective,
    FakeModeDirective,
    NzLoadingButtonDirective
  ]
})
export class DirectiveModule {
}
