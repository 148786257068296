import {Injectable} from "@angular/core";
import {Observable, Subject} from "rxjs";

@Injectable({providedIn: 'root'})
export class LoadingService {
  private state = new Subject<boolean>();

  setLoading(value: boolean) {
    this.state.next(value);
  }

  get loading(): Observable<boolean> {
    return this.state.asObservable();
  }
}
