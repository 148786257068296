import {NgModule} from "@angular/core";
import {BmI18nInputComponent} from "./bm-i18n-input.component";
import {CommonModule} from "@angular/common";
import {FormsModule} from "@angular/forms";
import {NzInputModule} from "ng-zorro-antd/input";
import {BmI18nPipe} from "./bm-i18n.pipe";

@NgModule({
  imports: [CommonModule, FormsModule, NzInputModule],
  declarations: [BmI18nInputComponent, BmI18nPipe],
  exports: [BmI18nInputComponent, BmI18nPipe]
})
export class BmI18nModule {
}
