import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest
} from '@angular/common/http';
import {finalize, Observable} from 'rxjs';
import {tap} from "rxjs/operators";
import {AuthService} from "@auth/auth.service";
import {AlertService} from "@shared/component/alert/alert.service";
import {Router} from "@angular/router";
import {FAKE_MODE} from "@app/app.constants";
import {TokenService} from "@core/token/token.service";
import {LoadingService} from "@core/loading.service";

@Injectable()
export class RequestHandlerInterceptor implements HttpInterceptor {
  constructor(private readonly tokenService: TokenService,
              private readonly authService: AuthService,
              private readonly router: Router,
              private readonly loadingService: LoadingService,
              private readonly alertService: AlertService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.tokenService.token.access_token;

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    if (FAKE_MODE && !request.params.has('skipFake')) {
      const prefix = request.url.substring(request.url.indexOf('api'), request.url.length);
      const extension = request.params.get('extension') || 'json';
      request = request.clone({
        method: 'GET',
        url: `${window.location.origin}/assets/fake/${prefix}.${extension}`,
        params: new HttpParams()
      })
    }

    this.loadingService.setLoading(true);
    return next.handle(request).pipe(
      tap({
        error: (errorResponse: HttpErrorResponse) => {
          if (errorResponse.status == 401 || errorResponse.status === 0) {
            return this.authService.logoutInClient();
          }

          if (errorResponse.status === 403) {
            return this.router.navigate(['403']);
          }

          const message = RequestHandlerInterceptor.getErrorMessage(errorResponse);
          this.alertService.error(message);
        },
      }),
      finalize(() => this.loadingService.setLoading(false))
    );
  }

  private static getErrorMessage(response: HttpErrorResponse): string {
    if (!response.error) {
      return response.statusText;
    }

    if (typeof response.error.error === 'string') {
      return response.error.error;
    }

    if (response.error.hasOwnProperty('exception')) {
      return response.error.exception.detail;
    }

    return response.error.title;
  }
}
