import {Directive, HostBinding, HostListener, Input} from '@angular/core';

@Directive({
  selector: 'img[src]'
})
export class DefaultImageDirective {
  @HostBinding('src')
  @Input()
  src: string;

  @Input()
  default: string;

  @HostListener('error')
  onError(): void {
    this.src = this.default;
  }
}
