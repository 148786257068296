import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'sortBy',
})
export class SortByPipe<T> implements PipeTransform {
  transform(array: any[], field: string): T[] {
    if (!Array.isArray(array)) {
      return array;
    }

    if (!field) {
      throw new Error('Sortable field not set');
    }

    return this.sortBy<T>(array, field);
  }

  sortBy<T>(array: T[], property: string): T[] {
    array.sort((a: T, b: T) => {
      if (a[property] < b[property]) return -1;
      if (a[property] > b[property]) return 1;
      else return 0;
    });

    return array;
  }
}
