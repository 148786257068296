import {Directive, TemplateRef, ViewContainerRef} from "@angular/core";
import {FAKE_MODE} from "@app/app.constants";

@Directive({
  selector: '[fakeMode]'
})
export class FakeModeDirective {
  constructor(private readonly templateRef: TemplateRef<any>,
              private readonly viewContainer: ViewContainerRef) {
    if (FAKE_MODE) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
