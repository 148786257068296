import {Pipe, PipeTransform} from "@angular/core";
import {I18n} from "../../model/i18n.model";
import {BmI18nService} from "./bm-i18n.service";

@Pipe({
  name: 'bmI18n'
})
export class BmI18nPipe implements PipeTransform {
  constructor(private readonly i18nService: BmI18nService) {
  }

  transform(i18n: I18n[] | object): string | null {
    return this.i18nService.getText(i18n);
  }
}
