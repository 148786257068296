import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {takeUntil} from "rxjs";
import {APP_VERSION, FAKE_MODE} from "@app/app.constants";
import {DestroyService} from "@core/destroy.service";
import {AlertService} from "@shared/component/alert/alert.service";
import {ReCaptchaV3Service} from "ng-recaptcha";
import {HttpErrorResponse} from "@angular/common/http";
import {Role} from "@config/role.constants";
import {LocalStorageService} from "ngx-webstorage";
import {inject} from "@shared/utils";
import {LoadingService} from "@core/loading.service";
import {AuthService} from "@auth/auth.service";
import {LoginCredentials} from "@auth/login/login-credentials.model";
import {UntypedFormBuilder, Validators} from "@angular/forms";

@Component({
  templateUrl: 'login.component.html',
  providers: [DestroyService]
})
export class LoginComponent {
  role = Role.AGENT;

  readonly version = APP_VERSION;
  readonly roles = Object.values(Role);
  readonly editForm = this.fb.group({
    username: [null, Validators.required],
    password: [null, Validators.required]
  });

  constructor(private readonly fb: UntypedFormBuilder,
              private readonly destroy$: DestroyService,
              private readonly router: Router,
              private readonly authService: AuthService,
              private readonly recaptchaService: ReCaptchaV3Service,
              private readonly loadingService: LoadingService,
              private readonly alertService: AlertService) {
  }

  onSubmit(): void {
    if (FAKE_MODE) {
      return this.login();
    }

    this.loadingService.setLoading(true);
    this.recaptchaService.execute('loginAction')
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((token: string) => this.login(token));
  }

  private login(recaptchaToken?: string): void {
    if (FAKE_MODE) {
      inject(LocalStorageService).store('fake-role', this.role);
    }

    const credentials: LoginCredentials = {
      ...this.editForm.getRawValue(),
      recaptchaToken: recaptchaToken
    };

    this.authService.login(credentials)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe({
        next: () => this.router.navigate(['dashboard']),
        error: (err: HttpErrorResponse) => {
          if (err.status === 401) {
            this.alertService.error('Неверные данные!!');
          }
          this.editForm.get('password').setValue(null);
        }
      })
  }
}
