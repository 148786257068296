import {Routes} from "@angular/router";
import {LoginComponent} from "@auth/login/login.component";
import {CanActivateGuard} from "@core/can-activate.guard";
import {ERROR_ROUTES} from "@core/result/error.route";

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [CanActivateGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    title: 'Авторизация',
    canActivate: ['canActivateLogin']
  },
  {
    path: 'offer',
    loadChildren: () => import('./offer/offer.module').then(m => m.OfferModule),
    canActivate: [CanActivateGuard]
  },
  ...ERROR_ROUTES
];
