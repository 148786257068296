import {Environment} from '@core/environment.model';

export const environment: Environment = {
  production: false,
  apiUrl: 'http://192.168.163.19',
  recaptchaSiteKey: '6Le2iAAgAAAAAEoRWdluLVBURwJ-JSFnbNQnlapA',
  googleAnalyticsTrackCode: 'G-6YL9C5095B',
  contentUrl: 'http://172.20.1.2/api/images/logo',
  fakeMode: true
};
