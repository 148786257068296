import {NgModule} from "@angular/core";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzIconModule} from "ng-zorro-antd/icon";
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {NzTableModule} from "ng-zorro-antd/table";
import {NzGridModule} from "ng-zorro-antd/grid";
import {NzPaginationModule} from "ng-zorro-antd/pagination";
import {NzModalModule} from "ng-zorro-antd/modal";
import {NzMessageModule} from "ng-zorro-antd/message";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import {NzBadgeModule} from "ng-zorro-antd/badge";
import {NzAlertModule} from "ng-zorro-antd/alert";

@NgModule({
  exports: [
    NzInputModule,
    NzButtonModule,
    NzIconModule,
    NzToolTipModule,
    NzTableModule,
    NzGridModule,
    NzPaginationModule,
    NzModalModule,
    NzMessageModule,
    NzCheckboxModule,
    NzBadgeModule,
    NzAlertModule
  ]
})
export class NgZorroModule {
}
