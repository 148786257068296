import {NgModule} from "@angular/core";
import {ModalHeaderComponent} from "./modal-header.component";
import {ModalCloserDirective} from "./modal-closer.directive";
import {LazyLoadImageModule} from "ng-lazyload-image";
import {CommonModule} from "@angular/common";
import {NzButtonModule} from "ng-zorro-antd/button";

@NgModule({
  imports: [LazyLoadImageModule, CommonModule, NzButtonModule],
  declarations: [ModalHeaderComponent, ModalCloserDirective],
  exports: [ModalHeaderComponent, ModalCloserDirective]
})
export class ModalHeaderModule {

}
