import {NgModule} from "@angular/core";
import {BmSelectInputModule} from "./select-input/bm-select-input.module";
import {BmI18nModule} from "./i18n-input/bm-i18n.module";
import {DatePickerComponent} from "./date-picker/date-picker.component";
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {FormsModule} from "@angular/forms";
import {ModalHeaderModule} from "../blocks/modal-header/modal-header.module";

@NgModule({
  imports: [BmSelectInputModule, BmI18nModule, NzDatePickerModule, FormsModule, ModalHeaderModule],
  declarations: [DatePickerComponent],
  exports: [BmSelectInputModule, BmI18nModule, DatePickerComponent, ModalHeaderModule],
})
export class ComponentModule {
}
